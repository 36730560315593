import CmsContentHeading from "components/cms/cmsContent/cmsContentHeading/cmsContentHeading";
import AnimationWrapper from "components/util/animationWrapper/animationWrapper";
import { useTranslation } from "next-i18next";
import { Col, Row } from "react-bootstrap";
import {
  contentElementBGColor,
  getBootstrapAlignmentRowClass,
  searchableID,
} from "utils/util.js";
import HeadlineSpace from "./headlineSpace";

const Headline = (props) => {
  const { t: tCms } = useTranslation("cms");
  const primaryHeadlineSettings = {
    headlineheading:
      props.settings.headlineheading &&
      props.content?.cfgHeadlineTypePrimary === "default"
        ? props.settings.headlineheading
        : props.content.cfgHeadlineTypePrimary,
    // chosen headlineStyle and if none is available then the same as the headlineType is chosen,
    // unless that is also unavailable, then its h3
    headlineHeadingStyle:
      props.settings.headlineheading &&
      props.content.cfgHeadlineStylePrimary === "default"
        ? props.settings.headlineheading
        : props.content.cfgHeadlineStylePrimary,
  };

  const secondaryHeadlineSettings = {
    headlineheading:
      props.settings.subheadlineheading &&
      props.content?.cfgHeadlineTypeSecondary === "default"
        ? props.settings.subheadlineheading
        : props.content.cfgHeadlineTypeSecondary,
    // chosen headlineStyle and if none is available then the same as the headlineType is chosen,
    // unless that is also unavailable, then its h3
    headlineHeadingStyle:
      props.settings.subheadlineheading &&
      props.content.cfgHeadlineStyleSecondary === "default"
        ? props.settings.subheadlineheading
        : props.content.cfgHeadlineStyleSecondary,
  };

  return (
    <>
      <div
        id={`anchor-${searchableID(props.content)}`}
        className={`${
          props.settings.spaceX === 0 ? "" : "container-fluid"
        } headline-container content-element ${
          props.content.cfgCustomClassName || ""
        } ${props.isLastElement ? "last-element" : ""} ${
          props.isFirstElement ? "first-element" : ""
        }`}
      >
        <Row
          id={
            props.content.cfgAnchorName
              ? `anchor-${props.content.cfgAnchorName}`
              : ""
          }
          className="g-0"
        >
          <Col className="" xs={12}>
            <AnimationWrapper
              animationIn={props.content.cfgAnimationIn}
              animationOut={props.content.cfgAnimationOut}
            >
              <Row
                className={`edge-to-edge-row ${getBootstrapAlignmentRowClass(
                  props.content.cfgHeadlineAlignPrimary
                )}`}
              >
                <Col xs={12}>
                  <CmsContentHeading
                    content={props.content.primaryHeadline}
                    cmsPosition={props.position}
                    cmsField="primaryHeadline"
                    settings={primaryHeadlineSettings}
                    cmsPlaceholder={tCms("headline-primaryHeadline")}
                    textAlign={props.content.cfgHeadlineAlignPrimary}
                  />
                </Col>
              </Row>
              <Row
                className={`edge-to-edge-row ${getBootstrapAlignmentRowClass(
                  props.content.cfgHeadlineAlignSecondary
                )}`}
              >
                <Col xs={12} className="secondary-headline-col">
                  <CmsContentHeading
                    content={props.content.secondaryHeadline}
                    cmsPosition={props.position}
                    cmsField="secondaryHeadline"
                    settings={secondaryHeadlineSettings}
                    cmsPlaceholder={tCms("headline-secondaryHeadline")}
                    textAlign={props.content.cfgHeadlineAlignSecondary}
                  />
                </Col>
              </Row>
            </AnimationWrapper>
          </Col>
        </Row>
      </div>
      <HeadlineSpace settings={props.settings} />
      <style jsx>{`
        .headline-container {
          position: static;
          background-color: ${contentElementBGColor(
            props.content.cfgBackgroundColor,
            props.settings
          )};
          :global(h1.heading:after),
          :gloabl(h2.heading:after),
          :global(.cms-content-heading.with-cms-h1:after),
          :global(.cms-content-heading.with-cms-h2:after) {
            margin-left: ${props.content.cfgHeadlineAlignPrimary === "left"
              ? "unset"
              : "auto"};
            margin-right: ${props.content.cfgHeadlineAlignPrimary === "right"
              ? "unset"
              : "auto"};
          }

          :global(.secondary-headline-col) {
            margin-top: ${props.settings.spacerHeadlinePX
              ? props.settings.spacerHeadlinePX + "px"
              : "0"};
          }
        }
      `}</style>
    </>
  );
};

export default Headline;
