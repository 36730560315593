import { globalSettings } from "services/globalSettings/globalSettingsService";
import { spaceInPercent } from "utils/util";

export default function HeadlineSpace({ settings }) {
  const {
    scalingfactorSpaceXMobile,
    scalingfactorSpaceYMobile,
    scalingfactorSpaceXTablet,
    scalingfactorSpaceYTablet,
    scalingfactorSpaceX2kplus,
    scalingfactorSpaceY2kplus,
  } = globalSettings.layout;

  const spaceXrow = settings.spaceX - 8 < 0 ? 0 : settings.spaceX - 8; // 8, 8+ or 0
  const spaceXcontainer =
    settings.spaceX !== 0
      ? 8 - settings.spaceX > 0
        ? 8 - settings.spaceX
        : 8
      : 0; // 0, 1-7 or 8
  return (
    <style jsx global>{`
      .headline-container {
        /* Container Mobile */
        padding-left: ${spaceXcontainer *
        scalingfactorSpaceXMobile}px !important;
        padding-right: ${spaceXcontainer *
        scalingfactorSpaceXMobile}px !important;

        /* Container Tablet */
        @media (min-width: ${globalSettings.responsive.breakpointmobile}px) {
          padding-left: ${spaceXcontainer *
          scalingfactorSpaceXTablet}px !important;
          padding-right: ${spaceXcontainer *
          scalingfactorSpaceXTablet}px !important;
        }

        /* Container Desktop */
        @media (min-width: ${globalSettings.responsive.breakpointdesktop}px) {
          padding-left: ${spaceXcontainer}px !important;
          padding-right: ${spaceXcontainer}px !important;
        }

        /* Container 2k Desktop */
        @media (min-width: ${globalSettings.responsive.breakpoint2k}px) {
          padding-left: ${spaceXcontainer *
          scalingfactorSpaceX2kplus}px !important;
          padding-right: ${spaceXcontainer *
          scalingfactorSpaceX2kplus}px !important;
        }
        & > div {
          padding-top: ${settings.spaceY *
          scalingfactorSpaceYMobile}px !important;
          padding-bottom: ${settings.spaceY *
          scalingfactorSpaceYMobile}px !important;
          padding-left: ${spaceXrow * scalingfactorSpaceXMobile}px !important;
          padding-right: ${spaceXrow * scalingfactorSpaceXMobile}px !important;

          /* Note: This would be mobile with percent-based padding:
            padding-left: ${spaceInPercent(
            spaceXrow,
            globalSettings.responsive.breakpointmobile,
            scalingfactorSpaceXMobile
          )} !important;
            padding-right: ${spaceInPercent(
            spaceXrow,
            globalSettings.responsive.breakpointmobile,
            scalingfactorSpaceXMobile
          )} !important;
            */

          @media (min-width: ${globalSettings.responsive.breakpointmobile}px) {
            padding-top: ${settings.spaceY *
            scalingfactorSpaceYTablet}px !important;
            padding-bottom: ${settings.spaceY *
            scalingfactorSpaceYTablet}px !important;
            padding-left: ${spaceInPercent(
              spaceXrow,
              globalSettings.responsive.breakpointdesktop,
              scalingfactorSpaceXTablet
            )} !important;
            padding-right: ${spaceInPercent(
              spaceXrow,
              globalSettings.responsive.breakpointdesktop,
              scalingfactorSpaceXTablet
            )} !important;
          }
          @media (min-width: ${globalSettings.responsive.breakpointdesktop}px) {
            padding-top: ${settings.spaceY}px !important;
            padding-bottom: ${settings.spaceY}px !important;
            padding-left: ${spaceInPercent(spaceXrow, 1720, 1)} !important;
            padding-right: ${spaceInPercent(spaceXrow, 1720, 1)} !important;
          }

          /* FullHD-Dimension */
          /* Note: Only (!) between 1720px and 2120px the exact spaceX/Y-Px-Value is used.
            All other media-queries use to percent transformed values. */
          @media (min-width: 1720px) {
            padding-top: ${settings.spaceY}px !important;
            padding-bottom: ${settings.spaceY}px !important;
            padding-left: ${spaceXrow}px !important;
            padding-right: ${spaceXrow}px !important;
          }

          /* above FullHD-Dimension */
          @media (min-width: 2120px) {
            padding-top: ${settings.spaceY}px !important;
            padding-bottom: ${settings.spaceY}px !important;
            padding-left: ${spaceInPercent(
              spaceXrow,
              globalSettings.responsive.breakpoint2k,
              1
            )} !important;
            padding-right: ${spaceInPercent(
              spaceXrow,
              globalSettings.responsive.breakpoint2k,
              1
            )} !important;
          }

          /* WQHD */
          @media (min-width: ${globalSettings.responsive.breakpoint2k}px) {
            padding-top: ${settings.spaceY *
            scalingfactorSpaceY2kplus}px !important;
            padding-bottom: ${settings.spaceY *
            scalingfactorSpaceY2kplus}px !important;
            padding-left: ${spaceInPercent(
              spaceXrow,
              globalSettings.responsive.breakpoint2k,
              scalingfactorSpaceX2kplus
            )} !important;
            padding-right: ${spaceInPercent(
              spaceXrow,
              globalSettings.responsive.breakpoint2k,
              scalingfactorSpaceX2kplus
            )} !important;
          }
        }
      }
    `}</style>
  );
}
